<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M21.2682 15.3598C21.5773 15.7307 21.5773 16.2694 21.2682 16.6402L13.7682 25.6402C13.4147 26.0645 12.7841 26.1218 12.3598 25.7682C11.9356 25.4147 11.8782 24.7841 12.2318 24.3598L19.1983 16L12.2318 7.64021C11.8782 7.21593 11.9356 6.58537 12.3598 6.2318C12.7841 5.87824 13.4147 5.93556 13.7682 6.35984L21.2682 15.3598Z"
		></path>
	</svg>
</template>

<script>
export default defineComponent({
	name: "IconChevronRight",
});
</script>
